import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {mql} from "./utils";
import {remRate} from "./utils";
import Choices from "choices.js";
import { gsap } from "gsap";
//import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import ScrollMagic from "scrollmagic";
//import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
// let Rellax = require('rellax');
require('lightgallery.js');
// require('lg-zoom.js');
// require('lg-fullscreen.js');
// require('lg-thumbnail.js');
require('lg-video.js');


export default function() {
  window.separateNumber = function(number) {
      return (number+'').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  };


  (function makeNewsAnimation(){
    const newsArray = document.querySelectorAll('.news__item');
    if (newsArray.length == 0 || window.innerWidth < 1025) return;
    let notActiveScrollDistance = Math.round(window.innerHeight * 0.05);
    let controller = new ScrollMagic.Controller({globalSceneOptions: {duration: Math.round(+window.innerHeight) * 0.8}});
    let triggerEl;
    let news = document.querySelector('.news');
    let newsDecoratesArray = document.querySelectorAll('.news__decorate-triangles');

    newsArray.forEach((el, ind)=>{
      triggerEl = el;

      if (ind == 0 || ind == 1 || ind == 2) {
        triggerEl = news;
      }

      if (el.classList.contains('news__item--fullscreen') || el.classList.contains('news__item--fullscreen2')) {
        notActiveScrollDistance = -10;
      }

      let scene = new ScrollMagic.Scene({
        triggerElement: triggerEl,
        offset: notActiveScrollDistance,
      })
          // .addIndicators({name: ind + 1})
          .addTo(controller)
          // .setClassToggle(el, 'active')

      .on("enter", function(event){
        el.classList.add('active');
      })
    });

    newsDecoratesArray.forEach((el, ind) =>{
      let left = el.querySelector('.left-triangle');
      let right = el.querySelector('.right-triangle');
      let image = el.querySelector('.image-triangle');

      let scene = new ScrollMagic.Scene({
        triggerElement: el,
        offset: notActiveScrollDistance,
      })
          // .addIndicators({name: ind + 1})
          .addTo(controller)

          .on("enter", function(event) {
            el.classList.add('active');
            let firstAnimatedEl = document.getElementById("scroll-animation-1");
            let secondAnimatedEl = document.getElementById("scroll-animation-2");
            let thirdAnimatedEl = document.getElementById("scroll-animation-3");
            if (firstAnimatedEl) firstAnimatedEl.beginElement();
            if (secondAnimatedEl) secondAnimatedEl.beginElement();
            if (thirdAnimatedEl) thirdAnimatedEl.beginElement();
            // document.getElementById("scroll-animation-1").beginElement();
            // document.getElementById("scroll-animation-2").beginElement();
            // document.getElementById("scroll-animation-3").beginElement();
          });

    })
  })();

  (function makePromoItemsAnimation() {
    const promoHolder = document.querySelector('.promo__list');

    if (!promoHolder || window.innerWidth < 1025) return;

    let items = promoHolder.querySelectorAll('.promo__item');
    let controller = new ScrollMagic.Controller({globalSceneOptions: {duration: Math.round(+window.innerHeight) * 0.8}});

    let scene = new ScrollMagic.Scene({
      triggerElement: promoHolder,
      offset: -100,
    })
        // .addIndicators({name: ind + 1})
        .addTo(controller)

        .on("enter", function(event) {
          promoHolder.classList.add('active');
          setTimeout(()=>{
            promoHolder.classList.add('let-action');
          }, 4000)
        });


    if (window.innerWidth > 1024) {
      items[0].classList.add('hovered');
      items.forEach((el)=>{
        el.addEventListener('mouseover', ()=>{
          items.forEach((item)=>{
            item.classList.remove('hovered');
          });
          el.classList.add('hovered');
        })
      })
    }


  })();

  (function makeBurger(){
    const burger = document.querySelector('.js-burger');
    const menu = document.querySelector('.menu');

    if (window.innerWidth > 1025 || !burger || !menu) return;

    const menuCloseButton = menu.querySelector('.menu__close-button');
    const menuWrapper = menu.querySelector('.menu__wrapper');

    burger.addEventListener('click', ()=>{
      menu.classList.add('menu--triggered');
      disableBodyScroll(menu);
    });

    menuCloseButton.addEventListener('click',()=>{
      menu.classList.remove('menu--triggered');
      enableBodyScroll(menu);
    })
  })();



  (function makeMenuSubList(){
    const menu = document.querySelector('.menu');

    if (!menu) return;

    const menuSubListsArray = menu.querySelectorAll('.menu__item--with-drop');
    menuSubListsArray.forEach((sublist)=>{
      let subLink = sublist.querySelector('.menu__link');
      let subMenu = sublist.querySelector('.menu__sub-list');
      let sublistHeight = 0;
      let subItem = subMenu.querySelectorAll('.menu__sub-item');

      subItem.forEach((subitem)=>{
        sublistHeight = sublistHeight + subitem.offsetHeight;
      });

      subLink.addEventListener('click', (e)=>{
        if (e.target.closest('.menu__arrow')){
          e.preventDefault();
          sublist.classList.toggle('menu__item--triggered');
        }
        if (sublist.classList.contains('menu__item--triggered')) {
          subMenu.style.height = remRate*8 + sublistHeight + 'px';
        } else {
          subMenu.style.height = 0;
        }
      })
    })
  })();



  window.makeFormSelects = function(){
    const selectArray = document.querySelectorAll('.js-select');

    if (selectArray.length == 0) return;

    const url = window.location.pathname;

    const singleSelectOptions = {
      searchEnabled: false,
      shouldSort: false,
      placeholder: true,
      placeholderValue: 'Выберете тему вопроса',

    };
    const multiSelectOptions = {
      searchEnabled: true,
      removeItemButton: true,
      shouldSort: false,
      placeholder: true,
      placeholderValue:  url.includes('/en/') ? 'Select a channel' : 'Выберите канал',
    };

    selectArray.forEach((select)=>{

      let choicesOption;
      if (select.classList.contains('multi')){
        choicesOption = multiSelectOptions;
      } else {
        choicesOption = singleSelectOptions;
      }
      const choices = new Choices(select, choicesOption);

      select.addEventListener('choice', ()=>{
        choices.hideDropdown();
      })
    });

  };



  (function makeParamsTabs(){
    const paramsHolder = document.querySelector('.params');
    if (!paramsHolder)return;
    const tabs = paramsHolder.querySelectorAll('.params__tab');
    const tables = paramsHolder.querySelectorAll('.params__data-block');
    if (tabs.length < 2) return;

    tabs.forEach((tab)=>{
      tab.addEventListener('click', ()=>{
        tabs.forEach((el)=>{
          el.classList.remove('params__tab--checked')
        });
        tab.classList.add('params__tab--checked');
        tables.forEach((table)=>{
          if (table.getAttribute('data-block') == tab.getAttribute('data-id')){
            table.style.display = 'block';
          } else {
            table.style.display = 'none';
          }
        })
      });
    })
  })();



  (function makeDocTabs(){
    const paramsHolder = document.querySelector('.documents');
    if (!paramsHolder)return;
    const tabs = paramsHolder.querySelectorAll('.documents__tab');
    const tables = paramsHolder.querySelectorAll('.documents__data-block');
    if (tabs.length < 2) return;

    tabs.forEach((tab)=>{
      tab.addEventListener('click', ()=>{
        tabs.forEach((el)=>{
          el.classList.remove('documents__tab--checked')
        });
        tab.classList.add('documents__tab--checked');
        tables.forEach((table)=>{
          if (table.getAttribute('data-block') == tab.getAttribute('data-id')){
            table.style.display = 'block';
          } else {
            table.style.display = 'none';
          }
        })
      });
    })
  })();



  (function correctCardsOnAdaptive() {
    const cardsHolder = document.querySelector('.cards');
    if (!cardsHolder) return;
    if (window.innerWidth > 1024) return;
    const list = cardsHolder.querySelector('.cards__list');
    const items = list.querySelectorAll('li');
    if (!list || items.length == 0) return;
    items.forEach((el)=>{
      if (el.classList.contains('cards__item-fake')){
        list.removeChild(el);
      }
    })
  })();



  (function makeAuditAdditionalInfo(){
    const auditHolder = document.querySelector('.audit');
    if (!auditHolder)  return;
    const animatedBlocksArray = auditHolder.querySelectorAll('.audit__block');
    if(!animatedBlocksArray) return;

    animatedBlocksArray.forEach((el)=>{
      const animatedBlock = el.querySelector('.audit__list-holder');
      const trigger = el.querySelector('.js-audit-trigger');
      trigger.addEventListener('click', (evt)=>{
        evt.preventDefault();
        let height = 0;
        animatedBlock.classList.toggle('audit__list-holder--triggered');
        if (animatedBlock.classList.contains('audit__list-holder--triggered')){
          height = animatedBlock.querySelector('.audit__list').offsetHeight;
          // animatedBlock.style.height = height + 'px';
        } else {
          // animatedBlock.style.height = 0;
        }
        animatedBlock.style.height = height + 'px';
      })
    })

  })();

  (function makePromoGallery(){



    const gallery = document.querySelector('#promo-gallery');
    if (!gallery) return;
    console.log(gallery);




    new lightGallery(gallery, {
      thumbnail: true,
      selector: '.promo__link',
      download: false,
      fullScreen: true,
      videoMaxWidth: '100%',
      vimeoPlayerParams: {
        maxHeight: '80%',

      }
    });

    gallery.addEventListener('onBeforeOpen', function(event){
      console.log('???');
    }, false)


  })();

  makeFormSelects();
};



